<template>
    <footer>
        <span class="copyright fontSmall">
            Todos os Direitos reservados - <b>Nio Cursos</b>
        </span>
    </footer>
</template>

<script>
export default {
    name: 'footer-component'
}
</script>
